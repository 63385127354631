<template>
  <b-form-group>
    <label for="room">
      Sala
      <span v-if="!required" class="help">(Opcional)</span>
    </label>
    <multiselect
      id="room"
      track-by="id"
      class="with-border"
      v-model="room"
      :options="rooms"
      :showLabels="false"
      :allowEmpty="true"
      :placeholder="true ? 'Adicionar sala ou setor' : 'Sem sala/setor disponível'"
      @select="onSelect"
      @remove="clearRoom"
      :key="reloadRooms"
      :disabled="disabled"
    >
      <template slot="caret">
        <div class="chevron">
          <ChevronDown />
        </div>
      </template>
      <template slot="singleLabel" slot-scope="props">
        <p class="room-name">{{props.option.name}}</p>
      </template>
      <template slot="option" slot-scope="props">
        <div class="d-flex">
          <p class="room-name">{{props.option.name }}</p>
          <small class="ml-auto text-danger" v-if="room && room.name === props.option.name">remover</small>
        </div>
        <span class="d-block" v-for="equipment in props.option?.equipments" :key="equipment.id">
          <small class="text-muted">{{ equipment.name }} (fixo)</small>
        </span>
      </template>
      <template slot="noOptions">
        <div class="no-rooms-description">
          Nenhuma sala disponível para os itens selecionados
        </div>
      </template>
      <template slot="noResult">
        Nenhum resultado
      </template>
    </multiselect>
    <div v-if="validated && !value" class="custom-invalid-feedback">Campo obrigatório</div>
  </b-form-group>
</template>
<script>
import { getCurrentClinic } from '@/utils/localStorageManager'

export default {
  props: {
    value: Object,
    availableProcedures: Array,
    validated: Boolean,
    required: Boolean,
    // clinicRooms: Array,
    clearRoom: Function,
    clearDate: Function,
    disabled: {
      type: Boolean,
      default: false
    }
  },
  components: { 
    ChevronDown: () => import('@/assets/icons/chevron-down.svg')
  },
  async created() {
    await this.getClinicRooms()
    // if (!this.clinicRooms) {
    //   await this.getClinicRooms()
    // }
  },
  data() {
    return {
      clinic: getCurrentClinic(),
      room: null,
      rooms: [],
      allRooms: [],
      reloadRooms: 0
    }
  },
  methods: {
    onSelect(room) {
      this.$emit('input', room)
      this.$emit('select', room)
      if(room) {
        this.room = room
        this.$emit('clearDate')
      }
    },
    async getClinicRooms() {
      try {
        const res = await this.api.getClinicRooms(this.clinic.id)
        this.allRooms = res.data.data
      } catch (error) {
        this.$toast.error('Não foi possível obter as salas, contate o suporte se o erro persistir')
      } finally {
        this.setRoomsOptions()
      }
    },
    setRoomsOptions() {
      this.rooms = []
      if(!this.allRooms?.length && !this.availableProcedures?.length) return
      this.allRooms.map(room => {
        this.availableProcedures.map( proc => {
          if (proc?.rooms) {
            proc.rooms.map(
              procRoom => {
                if (procRoom.id === room.id && !this.rooms.includes(room))
                this.rooms.push(room)
              }
            )
          }
        }) 
      })
      this.reloadRooms++
    },
  },
  watch: {
    value(val) {
      this.room = val
      this.setRoomsOptions()
    },
    availableProcedures: async function(val) {
      this.rooms = []
      if(val?.length) this.setRoomsOptions()
    }    
  }
}
</script>
<style lang="scss" scoped>
.no-rooms-description {
  font-size: 14px;
  font-style: italic;
  color: var(--type-active);
}

.room-name {
  margin-bottom: 0;
}
</style>
